import { useState } from 'react';
import { Button } from "@/Components/ui/button";
import PredesignTemplateView from "@/Modules/PredesignTemplateView";
import { Icon } from "@iconify/react";
import { Link } from "@inertiajs/react";
import StartDesignModal from "../components/StartDesignModal";
import { navigate } from '@/Hooks/Functions';
import { bothAction } from '@state/mode-both';
import { useQuery } from '@tanstack/react-query';

const fetchTemplates = async () => {
    const { data } = await window.axios.get(route("templates.public-paginate"), {
        params: {
            per_page: 5,
            order: 'random:asc',
            active: 1,
            ownership: 'system',
            page: 1,
        }
    });
    return data;
};

export default function CustomizeDesignBlock() {
    const [show, setShow] = useState(false);
    const [selected, setSelected] = useState(null);

    const { data, isLoading, error } = useQuery({
        queryKey: ['predesignTemplates'],
        queryFn: fetchTemplates,
        keepPreviousData: true,
    });

    const predesignTemplates = data?.data;

    const onUseDesign = async (item) => {
        setSelected(item);
        setShow(true);
    }

    const onSelect = async (product) => {
        setShow(false);
        bothAction.setToProductMode(true);
        navigate(route('designer.via-design-product-with-template', { slug: product.slug, uuid: selected?.uuid }));
    }
    return (<>
        <div className="w-full flex bg-abstract-4 bg-cover flex-col justify-center items-center py-4">
            <div className="w-full max-w-7xl flex flex-col pt-12 pb-6">
                <div className="flex flex-row justify-between md:py-6 py-2 md:px-0 px-4 items-center w-full">
                    <h1 data-aos="fade-down" className="md:text-3xl text-lg mb-4 md:px-0 px-4 text-center font-semibold font-nunito italic text-primary uppercase">
                        Customize Existing Designs To Your Style
                    </h1>
                    <Link href={route("home.predesign-templates")}>
                        <Button as="button" variant="theme" size="sm">
                            <span className='md:inline-flex hidden'>View More Predesigns</span>
                            <Icon icon="solar:double-alt-arrow-right-broken" width="30" height="30" />
                        </Button>
                    </Link>
                </div>
                <div className="grid lg:grid-cols-5 md:grid-cols-4 md:p-0 p-4 w-full sm:grid-cols-2 grid-cols-1 place-content-center place-items-center gap-4 flex-row">
                    {predesignTemplates?.map((template, index) => <PredesignTemplateView onUseDesign={(v) => onUseDesign(v)} item={template} data-aos={index % 2 === 0 ? 'fade-up' : 'fade-down'} key={template.id} />)}
                </div>
            </div>
        </div>
        <StartDesignModal title="Which product you want to design?" show={show} onClose={() => setShow(false)} onSelect={(v) => onSelect(v)} />
    </>
    )
}
